<template>
  <div class="container">
    <div class="ServiceHall">
      <div class="Hall">
        <div class="Hall-head">
          <div class="Hall-title">寻单大厅</div>
          <div class="Hall-delete select" @click="HallDelete">
            <img src="@/assets/imgs/delete.png" alt class="Hall-deleteimg" />
            <div @click="resetQueryParams">清空筛选条件</div>
          </div>
        </div>
        <div class="Hall-list">
          <div class="Hall-items" v-if="Platforms.Popular">
            <div class="Hall-list-title">热门需求:</div>
            <div class="flex-wrap">
              <div class="items items-border" :class="queryParam.itemsPId === item.id ? 'items-active' : ''"
                   v-for="(item, index) in Platforms.Popular" :key="index" @click="clickPopular(item)">
                <div>{{ item.itemsName }}</div>
              </div>
            </div>
          </div>
          <div class="Sort-info">
            <div class="Hall-list-title">需求信息:</div>
            <div class="Sort-menu">
              <div class="Menu">
                <Popover placeholder="请选择平台" plantName="platform_name" ref="Popover"
                         :list="platformList"
                         @checkItem="changePlatform" />
              </div>
              <div class="Menu">
                <el-cascader placeholder="请选择地区" @change="sortChange" v-model="queryParam.providerCity"
                             :options="cityOptions" :props="areaProps" filterable clearable></el-cascader>
              </div>
              <div class="Menu">
                <el-cascader placeholder="请选择类目" @change="changeCategory"
                             :options="categoryOptions" filterable clearable></el-cascader>
              </div>
              <div class="Menu">
                <PopoverTwo placeholder="请选择服务" plantName="itemsName" ref="Popover2"
                            :list="serviceList" @GetOpen="GetOpen" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="Sort">
        <div class="Sort-info">
          <div class="items" v-for="(item, index) in Sort" :key="index"
               :class="SortIndex === index ? 'items_active' : ''"
               @click="sortSwitch(index)">
            {{ item.name }}
            <i v-if="item.icon" class="iconfont icon-jiantou-xia-cuxiantiao"></i>
          </div>
        </div>
      </div>
      <div class="Lists" v-loading="loading">
        <div class="list-scroll" v-show="List.length">
          <list4 :list="List" @clickItem="clickGrab" @goDetail="goDetail"></list4>
        </div>
        <el-empty v-if="ServiceHallempty" :image="NoList">
          <div class="empty">
            <!-- <el-button type="danger">刷新一下 试试</el-button> -->
          </div>
        </el-empty>
        <Pagination :total="total" :size="queryParam.numPerPage" :page="queryParam.pageNum"
                    @handlePage="pageChange" v-if="total > queryParam.numPerPage" />
      </div>
    </div>
    <div class="stick-container" :style="'top: ' + stickyHeight">
      <el-image :src="require('@/assets/imgs/seeking-quick-publish.png')" />
      <div class="item">
        <el-select v-model="quickPost.need" placeholder="我需要" class="select">
          <el-option
              v-for="(item, index) in Platforms.Popular" :key="index"
              :label="item.itemsName"
              :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <el-input placeholder="请输入交易总额"
                  v-model="quickPost.amount">
          <!--<template #append>更换手机号</template>-->
        </el-input>
      </div>
      <div class="item button-container">
        <el-button class="submit" @click="clickQuickPublish">快速发布</el-button>
      </div>
    </div>
  </div>

</template>
<script>
import list4 from "@/components/List/components/list4.vue";
import commonJs from "../../assets/js/common";
import Pagination from "@/components/Pagination";
import Popover from "@/components/Popover/HallIndex.vue";
import PopoverTwo from "@/components/Popover/index2.vue";

export default {
  data() {
    return {
      Sort: [
        {
          name: "综合排序",
          type: "all",
        },
        {
          name: "发布时间",
          icon: true,
          type: "desc",
        },
      ],
      SortIndex: 0, //默认下标 为 0
      IndustryList: [],
      CategoryList: [],
      // 需求大厅数据
      List: [],
      total: 0, //需求大厅   总数据/条
      last_page: 1, //需求大厅 总页面
      queryParam: {
        pageNum: 1,
        numPerPage: 20,
        platformId: null,
        itemsId: null,
        itemsPId: null,
        providerCity: null,
        categoriesId: null,
      },
      // end
      Platforms: {
        Domestic: [],
        Abroad: [],
        Popular: [],
        All: [],
      },
      //   加载
      loading: true,
      ServiceHallempty: false,
      
      platList: [],
      Popover2Show: true,
      NoList: require("@/assets/imgs/no_hall.png"),
      quickPost: {
        need: '',
        amount: ''
      },
      areaProps: {
        value: 'id',
        label: 'name'
      },
    };
  },
  components: {
    list4,
    Pagination,
    Popover,
    PopoverTwo,
  },
  created() {
    this.getPopular();
    this.getList()
    // this.getResver();
  },
  mounted() {
  },
  methods: {
    resetQueryParams() {
    
    },
    // 点击快速发布
    clickQuickPublish() {
      if (!this.$store.getters.getUser || !this.$store.getters.getUser.id) {
        this.$notify.warning('您还未登录，请先登录')
        return
      }
      this.$router.push({
        name: 'SeekingHallPublish',
        query: this.quickPost
      })
    },
    // 点击抢单
    clickGrab(item) {
      if (!this.$store.getters.getUser || !this.$store.getters.getUser.id) {
        this.$notify.warning('您还未登录，请先登录')
        return
      }
      let id = item.userId;
      let userId = this.$store.getters.getUser.id;
      if (id === userId) {
        this.$notify.info('您不能抢自己的单')
        return
      }
      this.$axios
          .post(`app-api/hiring/requirement/grab`, {
            id: item.id
          })
          .then((res) => {
            if (res.data.code === 0) {
              this.$notify.success('抢单成功')
              // 进入任务订单列表
              this.$router.push({
                name: 'DemandCollectionList',
              })
            }
          })
          .catch((err) => {
            this.$message.error("获取需求详情失败！" + err)
          });
    },
    // 点击需求，进入详情页
    goDetail(payload) {
      this.$router.push({
        name: 'SeekingHallDetail',
        query: {
          id: payload.id
        }
      })
    },
    sortSwitch(index) {
      this.SortIndex = index;
      this.resettingHall();
      this.getList();
    },
    GetOpen(item) {
      this.queryParam.itemsId = item.ids;
      this.getList();
    },
    // 获取 配置项
    getResver() {
      if (!this.$store.getters.getConfig) {
        return setTimeout(() => {
          this.getResver();
        }, 500);
      }
      let Config = this.$store.getters.getConfig;
      this.Platforms.All = Config.servicePlatformList;
      this.$refs.Popover.list = Config.servicePlatformList;
      this.$refs.Popover2.list = Config.serviceItemsList;
      if (this.Popover2Show) {
        this.$refs.Popover2.activeName = Config.serviceItemsList[0].itemsName;
      } else {
        let serviceItemsList = this.$store.getters.getConfig.serviceItemsList;
        for (let x = 0; x < serviceItemsList.length; x++) {
          if (serviceItemsList[x].id === this.$route.params.id) {
            this.$refs.Popover2.activeName = serviceItemsList[x].itemsName;
          }
        }
        this.$refs.Popover2.Config.value = this.$route.params.value;
        this.$refs.Popover2.Config.ids = this.$route.params.ids;
        this.$refs.Popover2.Config.id = this.$route.params.id;
        this.$refs.Popover2.ListIndex = this.$route.params.ids;
        // console.log(this.$refs.Popover2.Config, "this.$refs.Popover2.Config");
        // console.log(this.$route.params, " this.$route.params");
      }
    },
    // 获取省市 数据
    getCityOptions() {
      let data = this.$store.getters.getCity;
      const pid = 1;
      this.Platform.CityList = commonJs.cityFilter(data, pid);
    },
    // 获取 热门服务
    getPopular() {
      this.$axios
          .get(`/app-api/hiring/service-items/popular/requirement`, {
            params: {
              num: 5
            }
          })
          .then((res) => {
            if (res.data.code === 0) {
              this.Platforms.Popular = res.data.data;
            } else {
              this.Platform.Popular = [];
            }
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
    // 重置数据
    resettingList(data) {
      let arr = [];
      for (let x in data) {
        let obj = {
          id: x,
          name: data[x],
        };
        arr.push(obj);
      }
      return arr;
    },
    // 重置需求大厅参数
    resettingHall() {
      this.queryParam = {
        pageNum: 1,
        numPerPage: 20,
        platformId: null,
        itemsId: null,
        itemsPId: null,
        providerCity: null,
        categoriesId: null,
      };
      this.List = [];
    },
    sortChange() {
      this.getList();
    },
    // 页面切换
    pageChange(val) {
      this.queryParam.pageNum = val;
      this.getList();
    },
    changePlatform(item) {
      this.resettingHall();
      this.queryParam.platformId = item.id;
      this.getList();
    },
    changeCategory(value) {
      if (value.length) {
        this.queryParam.categoriesId = value[0]
        this.getList()
      }
    },
    // 获取寻单大厅的数据
    getList() {
      let url = `/app-api/hiring/requirement/hall`;
      let payload = {
        pageNo: this.queryParam.pageNum,
        pageSize: this.queryParam.numPerPage,
      };
      // 行业分类
      if (this.queryParam.itemsPId) payload.hot = this.queryParam.itemsPId
      if (this.queryParam.platformId) payload.platformId = this.queryParam.platformId
      if (this.queryParam.providerCity) payload.providerCity = this.queryParam.providerCity[1]
      if (this.queryParam.categoriesId) payload.categoriesId = this.queryParam.categoriesId
      if (this.queryParam.itemsId) payload.itemsId = this.queryParam.itemsId
      if (this.SortIndex === 1) payload.createDesc = true
      
      // 当拿到的不是综合排序
      // if (this.SortIndex != 0) {
      //   url +=
      //       this.SortIndex == 1
      //           ? `&price=${this.Sort[this.SortIndex].type}`
      //           : `&orderNum=${this.Sort[this.SortIndex].type}`;
      // }
      
      this.loading = true;
      this.$axios
          .get(url, {params: payload})
          .then((res) => {
            if (res.data.code === 0) {
              this.loading = false;
              if (!res.data.data || !res.data.data.total) {
                this.ServiceHallempty = true;
                return this.List = [];
              }
              this.ServiceHallempty = false;
              this.List = res.data.data.list;
              this.total = res.data.data.total;
              // this.last_page = res.data.data.pre_page;
            }
          })
          .catch((err) => {
            this.loading = false;
            return Promise.reject(err);
          });
    },
    // 热门·服务
    clickPopular(item) {
      this.queryParam.pageNum = 1;
      this.queryParam.itemsPId = item.id;
      this.getList()
    },
    // 清除筛选信息
    HallDelete() {
      this.$modal.msgSuccess("清除筛选成功!");
      this.resettingHall()
      this.$refs.Popover.config.value = "";
      this.$refs.Popover.config.id = "";
      this.$refs.Popover2.Config.value = "";
      this.$refs.Popover2.Config.id = "";
      this.$refs.Popover2.Config.ids = "";
      // 进行筛选数据
      this.getList();
    },
  },
  computed: {
    stickyHeight() {
      return this.$store.getters.getStickyHeight
    },
    cityOptions() {
      return this.$store.getters.getCity;
    },
    categoryOptions() {
      let config = this.$store.getters.getConfig;
      return config.serviceCategories
    },
    platformList() {
      let config = this.$store.getters.getConfig;
      return config.servicePlatformList
    },
    serviceList() {
      let config = this.$store.getters.getConfig;
      return config.serviceItemsList
    }
  }
};
</script>
<style lang="less" scoped>


.container {
  display: flex;
  
  .ServiceHall {
    width: calc(100% - 370px);
  }
}

.stick-container {
  position: sticky;
  align-self: flex-start;
  z-index: 99;
  margin-left: 20px;
  background-color: #ffffff;
  box-shadow: 0 4px 4px 1px #e2eef4;
  
  .title {
    margin-bottom: 50px;
    font-size: 22px;
  }
  
  .item {
    margin: 20px 30px;
    height: 40px;
  }
  
  .select {
    display: block;
    
  }
  
  .button-container {
    margin-top: 40px;
    
    button.submit {
      width: 100%;
      background-color: #FC3737;
      border-radius: 3px;
      color: #ffffff;
      cursor: pointer;
      
      &:hover {
        filter: brightness(0.9);
      }
    }
  }
  
}

.ServiceHall {
  // min-width: 1920px;
  .Hall {
    //width: 1440px;
    margin: 0 auto;
    background: #ffffff;
    
    .Hall-head {
      height: 69px;
      //width: 100%;
      padding: 0 28px;
      box-sizing: border-box;
      border-bottom: 1px solid #dfe3ea;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    
    .Hall-title {
      color: #000000;
      font-size: 20px;
      line-height: 87px;
      font-weight: 700;
    }
    
    .Hall-delete {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-right: 20px;
    }
    
    .Hall-delete .Hall-deleteimg {
      width: 18px;
      height: 20px;
      margin-right: 4px;
    }
    
    .Hall-delete div {
      font-weight: 400;
      color: #333333;
      line-height: 23px;
      font-size: 16px;
    }
    
    .Hall-list {
      padding: 0 28px 28px;
      box-sizing: border-box;
      
      .Hall-items {
        display: flex;
        // align-items: center;
        margin-top: 24px;
      }
      
      .Hall-list-title {
        color: #999999;
        font-size: 20px;
        min-width: 110px;
        line-height: 58px;
      }
      
      .flex-wrap {
        display: flex;
        flex-wrap: wrap;
        margin-left: 13px;
        
        .items {
          margin: 5px 0;
          
          &:not(:nth-last-of-type(1)) {
            margin-right: 10px;
          }
        }
        
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    
    .items-border {
      border: 1px solid #e8e8e8;
    }
    
    .items {
      width: 161px;
      height: 58px;
      border-radius: 4px 4px 4px 4px;
      box-sizing: border-box;
      margin-right: 12px;
      cursor: pointer;
      
      &:nth-child(7n) {
        margin-right: 0 !important;
      }
      
      &:nth-child(n + 8) {
        margin-top: 20px;
      }
      
      display: flex;
      justify-content: center;
      align-items: center;
      
      &:hover {
        border: 1px solid #fc3737;
        // box-sizing: border-box;
        div {
          font-weight: 700;
        }
      }
    }
    
    .items-active {
      border: 1px solid #fc3737;
      // box-sizing: border-box;
      div {
        font-weight: 700;
      }
    }
    
    .items-center {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    
    .Sort-info {
      display: flex;
      align-items: center;
      margin-top: 24px;
      
      .Sort-menu {
        display: flex;
        align-items: center;
        margin-left: 13px;
        
        // justify-content: space-between;
        .Menu {
          margin-right: 12px;
          width: 154px;
          height: 47px;
        }
        
        /deep/ .el-dropdown {
          width: 100%;
          height: 100%;
        }
        
        /deep/ .el-input__inner {
          height: 47px;
        }
        
        .Name {
          color: #999999;
          font-size: 20px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          
          .select {
            display: block;
          }
          
          i {
            display: block;
            // font-size: 25px !important;
          }
        }
      }
    }
  }
  
  .Sort {
    height: 69px;
    top: 88px;
    z-index: 88;
    margin-top: 30px;
    
    .Sort-info {
      margin: 0 auto;
      display: flex;
      align-items: center;
      height: 100%;
      background: #ffffff;
      
      .items {
        color: #999999;
        font-size: 20px;
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 30px;
        cursor: pointer;
        margin-right: 12px;
        
        &:hover {
          color: #ffffff;
          background: #fc3737;
          
          i {
            color: #ffffff !important;
          }
        }
        
        img {
          height: 17px;
          margin-left: 4px;
        }
        
        i {
          margin-left: 4px;
          font-size: 25px;
          color: #999999;
        }
      }
      
      .items_active {
        color: #ffffff;
        background: #fc3737;
        
        i {
          color: #ffffff !important;
        }
      }
    }
  }
  
  .Lists {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    background: #ffffff;
    
    
    .list-scroll {
      height: 726px;
      overflow: hidden;
      width: 100%;
    }
  }
}

.el-empty {
  flex: 1;
}
</style>
