<template>
  <div class="list4">
    <vue-seamless-scroll :data="renderList" class="seamless-warp" :class-option="classOption">
      <div class="item" v-for="item in renderList" :key="item.id" @click="goDetail(item)">
        <el-avatar :size="100" icon="el-icon-picture" shape="square"
                   :src="item.image"></el-avatar>
        <div class="item-left">
          <div class="title">{{ item.title }}</div>
          <el-row type="flex" align="middle">
            <div class="info-container">
              <span class="info-item due-time">{{ item.dueTimeLabel }}</span>
              <span class="info-item">{{ item.platform }}</span>
              <span class="info-item">{{ item.category }}</span>
              <span class="info-item">{{ item.items }}</span>
            </div>
            <div class="deadline-container">
              <i class="el-icon-time"></i>
              <countdown :time="item.remainTime">
                <template slot-scope="props">{{ props.hours }}小时{{ props.minutes }}分钟后订单截止</template>
              </countdown>
            </div>
          </el-row>
          <el-row type="flex" align="middle">
            <el-tag size="mini" effect="dark" class="money">
              <i class="icon iconfont icon-safe" />
              安心购
            </el-tag>
            <el-tag size="mini" effect="dark" class="money">保证金{{ item.deposit }}</el-tag>
            <el-tag size="mini" effect="dark" class="type"
                    :class="item.providertype === 1 ? '' : 'business'"
            >{{ item.providertype === 1 ? '个' : '企' }}
            </el-tag>
          </el-row>
        </div>
        <div class="item-middle">
          <div class="price-container">
            <span class="symbol">￥</span>
            <span class="number">{{ item.amount }}</span>
            <span class="unit">元</span>
          </div>
          <span class="status">{{ item.state }}</span>
        </div>
        <button @click.stop="clickItem(item)" class="button grab">抢单</button>
      </div>
    </vue-seamless-scroll>
  
  </div>
</template>
<script>
import moment from "moment";
import { REQUIREMENT_STATE, DUE_TIME_TYPE } from "@/constants/requirement";

export default {
  props: ["list"],
  data() {
    return {
      name: "无缝轮播-注意只能用span",
      isShow: true,
    };
  },
  computed: {
    classOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        // limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
        getTime: new Date().getTime(),
      };
    },
    renderList() {
      let retList = this.list;
      if (!this.$store.getters.getConfig
          || !this.$store.getters.getConfig.serviceCategories
          || !this.$store.getters.getConfig.serviceItemsList
          || !this.$store.getters.getConfig.servicePlatformList
      ) return
      let categoryObj = this.$store.getters.getConfig.serviceCategories;
      let itemsList = this.$store.getters.getConfig.serviceItemsList;
      let platformList = this.$store.getters.getConfig.servicePlatformList;
      retList.forEach(item => {
        if (item.filePath) {
          item.images = item.filePath.split(',')
          if (item.images && item.images.length) item.image = item.images[0]
        }
        // 当前时间  + 3小时 - 支付时间
        if (item.expirationDate) item.remainTime = item.expirationDate - +moment()
        else item.remainTime = +moment(item.createTime).add(3, 'hours').diff(moment())
        
        if (item.achieveTimeType && item.achieveTimeInt) {
          let type = item.achieveTimeType === DUE_TIME_TYPE.HOUR.value ? DUE_TIME_TYPE.HOUR.label : DUE_TIME_TYPE.DAY.label
          item.dueTimeLabel = item.achieveTimeInt + type + '交付'
        }
        // 匹配状态
        let key = Object.keys(REQUIREMENT_STATE).find(key => +REQUIREMENT_STATE[key].value === item.requirementState)
        if (key) item.state = REQUIREMENT_STATE[key].label
        // 平台
        let platform = platformList.find(p => p.id === item.platformId)
        if (platform) item.platform = platform.platformName
        // 服务类目
        let categoryKey = Object.keys(categoryObj).find(key => +key === item.categoriesId)
        if (categoryKey) item.category = categoryObj[categoryKey].label
        // 服务项目
        itemsList.forEach(p => {
          if (p.children) {
            let items = p.children.find(i => i.id === item.itemsId)
            if (items) {
              item.items = items.itemsName
            }
          }
        })
      })
      return retList;
    }
  },
  methods: {
    moment,
    clickItem(item) {
      this.$emit("clickItem", item);
    },
    goDetail(item) {
      this.$emit("goDetail", item);
    },
    transform(props) {
      Object.entries(props).forEach(([key, value]) => {
        // Adds leading zero
        const digits = value < 10 ? `0${value}` : value;
        props[key] = `${digits}`;
      });
      
      return props;
    },
  }
};
</script>
<style lang="less" scoped>
@main-color: #FC3737;
@second-color: #555555;

.item {
  background-color: #ffffff;
  padding: 20px 36px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #DFE3EA;
  cursor: pointer;
  
  .item-left {
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: 14px;
    margin-left: 10px;
    
    > * {
      margin: 5px 0;
    }
    
    .title {
      font-size: 18px;
    }
    
    .info-container {
      display: flex;
      align-items: center;
      color: #555555;
      
      .info-item:not(:nth-last-of-type(1))::after {
        content: '/';
      }
      
    }
    
    .deadline-container {
      margin-left: 30px;
      color: #555555;
    }
  }
  
  .item-middle {
    display: flex;
    flex-direction: column;
    flex: 1;
    
    .price-container {
      display: flex;
      align-items: baseline;
      color: @main-color;
      font-size: 12px;
      
      .number {
        font-size: 18px;
        font-weight: 600;
      }
    }
    
    .status {
      color: @second-color;
      font-size: 14px;
      margin-top: 10px;
    }
    
  }
  
  button {
    color: #ffffff;
    background-color: @main-color;
    cursor: pointer;
    font-size: 12px;
    border-radius: 8px;
    height: 36px;
    width: 120px;
    padding: 0 24px;
  }
  
  .el-tag {
    margin: 0;
    border: none;
    line-height: 20px;
    
    & + .el-tag {
      margin-left: 5px;
    }
    
    &.tag {
      background-color: #FFB40E;
    }
    
    &.type {
      background-color: #F46700;
    }
    
    &.business {
      background-color: #3D8BFF
    }
    
    &.money {
      background-color: #36A04C;
      
      .icon {
        font-size: 14px;
      }
    }
  }
}
</style>
